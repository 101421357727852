<template>
  <div>
    <!-- picFDD18D3C5809DA5E2A57A90F60F29435.jpg -->
    <img style="width: 440px; height: 330px;" src="@/assets/news-network/create-digital-designs-the-lu-way-1.jpg" class="rounded">

    <p>
      What could be better than diving into a pile of LEGO bricks and beginning to build?
    </p>
    <p>
      Creative play seems simple, but it’s really kinda’ complex when you think about it. Builders make millions of decisions developing the stuff of their dreams, and they have lots of fun answering questions like “How can I keep these connections strong? What’s a suitable size? What color bricks look coolest?”
    </p>
    <p>
      LEGO Universe’s art director, Phillip Atencio, and his team grapple with similar fun conundrums daily. Since the art team is constantly learning the best ways to build with LEGO in the digital domain, they have lots to teach the game’s co-creators (that’s you!) about evolving your massive new online gameworld!
    </p>
    <p>
      You’re reading part one in a special LEGO Universe art series. Each of the short stories in the series will highlight part of the development team’s creative process. Members of the LEGO Universe art team will guide you along the way, using their work to teach you how to take your own creations to the next level!
    </p>
    <p>
      Subscribe to the LEGO Universe News Network RSS feed to be sure you don’t miss a single installment in this special series. In part two we’ll meet LEGO Universe’s lead concept artist and learn what it takes for his team to get their graphic ideas injected into the game!
    </p>
    <p>
      The entire LEGO Universe team hopes you’re inspired by this special series as you co-create the most fantastic and fun-filled massively multiplayer online game on earth!
    </p>
  </div>
</template>
